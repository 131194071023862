@import url('https://fonts.googleapis.com/css2?family=REM:ital,wght@0,100..900;1,100..900&display=swap');



.contenedor-banner-inicio{
  background-image: url(../img/banner-inicio.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;

  height: 90vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.contenedor-texto-inicio{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  text-align: center;

  width: 100%;
  max-width: 50%;
}

.titulo-inicio{
  font-family: 'REM', sans-serif;
  font-weight: bold;
  color: #F8F9FA;
}

.contenedor-secciones-inicio{
  width: 100%;
  max-width: 80%;

  margin: 100px auto;
}

.contenedor-titulo-secciones{
  margin-bottom: 50px;
}

.titulo-seccion{
  font-family: 'REM';
}

.subtitulo-seccion{
  font-family: 'REM';
  font-weight: 300;
  color: #828282;
}


.contenedor-beneficios{
  display: flex;
  flex-wrap: wrap;

  gap: 20px;

  width: 100%;
}

.item-beneficio{
  height: auto;

  background-color: #fff;
  box-shadow: 0 8px 16px rgba(0, 0, 111, 0.2);
  border-radius: 30px;

  padding: 50px;

  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;

  flex-grow: 1;
  flex-basis: 6.25rem;

  text-align: start;

}

.contenedor-ver-documentos{
  display: flex;
  flex-direction: column;

  justify-content: center;
  align-items: center;

  margin: 50px auto;
}

.ver-documentos{
  font-family: 'REM';
  font-size: 18px;

  padding: .5rem 2rem;

  color: #000066;
  background-color: #8A8AFF;

  box-shadow: 0 8px 16px rgba(0, 0, 111, 0.2);

  text-decoration: none;
  border-radius: 5px;

  transition: transform 0.3s;
}

.ver-documentos:hover{
  transform: translateY(-10px);
  box-shadow: none;
  background-color: #3D3DFF;
  color: #fff;
}