.contenedor-documentos{
    display: flex;
    width: 100%;
    max-width: 100%;
    gap: 20px;
    flex-wrap: wrap;
}

.item-documento{
    height: auto;

    background-color: #fff;
    box-shadow: 0 4px 20px rgba(0, 0, 111, 0.2);
    border-radius: 30px;

    padding: 50px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

    flex-grow: 1;
    flex-basis: 6.25rem;

    text-align: start;
}

.titulo-documento{
    font-family: 'rem';
    color: #000066;

    margin-bottom: 25px;
    
    text-decoration: none;
}

.resumen-doc{
    font-family: 'rem';
    font-size: 16px;
    color: #828282;
    font-weight: 300;

    margin-bottom: 25px;
}

.ver-mas{
    font-size: 16px;

    text-decoration: none;
    color: #828282;

    display: inline-block;
    padding-bottom: 2px;
    background-image: linear-gradient(#000066 0 0);
    background-position: 0 100%; /* OR bottom left */
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /* change after the size immediately */
}

.ver-mas:hover {
  background-position: 0 100%; /* OR bottom left */
  background-size: 100% 2px;

  
  color: #000066;
}

.icono-ver-mas{
    font-size: 17px;

    vertical-align: middle;

    padding-left: 5px;
}
