.contenedor-pagination {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    list-style-type: none;
    padding: 0;

}

.item-pagintaion {
    margin: 5px;
}

.item-pagination-number {
    margin: 5px;
  }
  
.pagination-btn-number {
    border: none;
    padding: 6px 12px;
    cursor: pointer;
    background: none;
    outline: none;
    text-shadow: none; /* Asegúrate de eliminar cualquier sombra de texto predeterminada */
}
  
.pagination-btn-number:focus {
    color: #3D3DFF; /* Color del texto cuando se pasa el mouse sobre el botón activo */
    text-shadow: 0 4px 20px rgba(0, 86, 179, 0.5);
}
  
.item-pagination-number.activo .pagination-btn-number {
    color: #3D3DFF;
    text-shadow: 0 4px 20px rgba(0, 86, 179, 0.5); /* Sombra de texto para el estado activo */
}
  
.item-pagination-number.activo .pagination-btn-number:hover {
    color: #3D3DFF; /* Color del texto cuando se pasa el mouse sobre el botón activo */
    text-shadow: 0 4px 20px rgba(0, 86, 179, 0.5); /* Sombra de texto para el estado activo y hover */
}
  
.pagination-btn-number:hover {
    color: #3D3DFF; /* Color del texto cuando se pasa el mouse sobre el botón */
    text-shadow: 0 4px 20px rgba(0, 86, 179, 0.5); /* Sombra de texto para el estado hover */
}
  

.pagination-btn {
    box-shadow: 0 4px 20px rgba(0, 0, 111, 0.2);
    border-radius: 5px;
    border: none;

    padding: 5px 10px;
    cursor: pointer;
    background-color: #8A8AFF;
    color: #000066;;
}

.pagination-btn:disabled,
.item-pagintaion.disabled .pagination-btn {
    cursor: not-allowed;
    opacity: 0.5;
}

.pagination-btn:hover:not(:disabled) {
    background-color: #3D3DFF;
    color: #fff;
}
  
.item-pagintaion.disabled .pagination-btn {
    pointer-events: none;
}
