.contenedor-footer{
    width: 100%;
    max-width: 100%;

    background-color: #ECECEC;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    padding: 50px;
}

.contenedor-paginas{
    display: flex;
    flex-wrap: wrap;

    margin-top: 25px;

    border-bottom: 1px solid #cbcbcb;
}

.paginas-footer{

    height: auto;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    flex-grow: 1;
    flex-basis: 1.25rem;

    font-family: 'rem';
    margin: 10px;

    color: #828282;
    text-decoration: none;

    font-weight: 300;

    font-style: italic;
}

.paginas-footer:hover{
    color: #000066;
}

.contenedor-redes{
    display: flex;
    flex-direction: row;

    margin-top: 25px;
}

.iconos{
    color: #828282;
    margin: 10px;
    font-size: 30px;
}

.iconos:hover{
    color: #000066;

}

.contenedor-copy{
    margin-top: 25px;
}

.texto-copy{
    font-family: 'rem';
    font-weight: 300;

    color: #828282;
}

.no-link{
    text-decoration: none;
    color: #828282;
}

