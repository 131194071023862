.contenedor-posts{
    width: 100%;
    max-width: 80%;

    margin: 100px auto;

    font-family: 'rem';
    font-size: 16px;

    display: flex;
    flex-direction: row;

    gap: 20px;

}

.contenedor-wp{
    display: flex;
    flex-direction: column;

    text-align: start;

    width: 100%;
    max-width: 70%;

}

.contenedor-entradas{
    width: 100%;
    max-width: 30%;

    display: flex;
    flex-direction: column-reverse;

    align-items: flex-end;
    justify-content: flex-end;


}

.post-title{
    font-family: 'rem';
    font-weight: bold;

    margin-bottom: 50px;
}

.contenedor-parrafos{
    width: 100%;
    max-width: 100%;

    display: flex;
    flex-direction: row;

}

.wp-block-file{
    margin: 50px 0 50px 0;

    display: flex;
    flex-direction: row;

    gap: 20px;
}

.wp-block-file object{

    width: 100%;
    max-width: 80%;

}

.post-content {
    width: 100%;
    max-width: 100%;
    word-wrap: break-word;
    margin: auto;
}

.post-content img {
    max-width: 100%;
    height: auto;
}

.post-content iframe {
    width: 100%;
    max-width: 70%;
    height: 100%;
}

.wp-block-file a{

    height: 100%;

    text-decoration: none;
    color: #000066;

    box-shadow: 0 8px 16px rgba(0, 0, 111, 0.2);

    padding: 10px 15px 10px 15px;

    border-radius: 10px;
    background-color: #8A8AFF;
    transition: transform 0.3s;
}

.wp-block-file a:hover{
    transform: translateY(-10px);
    box-shadow: none;
    background-color: #3D3DFF;
    color: #fff;
}

.contenedor-entradas-post{

    padding: 20px 30px 20px 30px;
    border: 1px solid #cbcbcb;

}

.contenedor-titulo-entrada-posts{

    margin: 0 0 50px 0;

}

.titulo-entradas-posts{
    font-family: 'rem';
    font-weight: bold;
}

.item-entrada-post{

    display: flex;

    border-bottom: 3px solid #cbcbcb;

}

.item-entrada-post:last-child{
    border-bottom: none;
}

.link-entrada-post{
    text-decoration: none;

    padding: 20px;

    color: #828282;

    transition: transform 0.3s;
}

.link-entrada-post:hover{
    color: #000066;
}

@media (max-width: 700px){

    .contenedor-posts{

        flex-direction: column;
    
        gap: 20px;
    
    }

    .contenedor-wp{
    
        max-width: 100%;
    
    }

    .contenedor-entradas{
        max-width: 100%;
    
        margin: auto;
    }

    .wp-block-file{

        margin: 50px 0;
        flex-direction: column;

    }

    .post-content iframe {

        max-width: 100%;
    }

}