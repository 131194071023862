.contenedor-contacto{
    width: 100%;
    max-width: 80%;

    margin: auto;

    font-family: 'rem';
    font-size: 16px;
}

.contenedor-form{
    display: flex;
    flex-direction: column;

    margin-bottom: 100px;

}

.contenedor-titulo{
    margin: 100px 0;
}

.contenedor-titulo h1{
    font-family: rem;
    font-weight: bold;

    color: #000066;

    text-align: center;
}

.contenedor-flex-1{
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 50%;

    margin: 25px auto;

    gap: 50px;

    justify-content: center;


}

.contenedor-inputs{
    display: flex;
    flex-direction: column;

    width: 100%;
}

.contenedor-inputs label{
    font-family: 'rem';
    font-size: 1.2rem;
    padding: 10px 0;

    color: #000066;
}

.contenedor-inputs input,
.contenedor-inputs textarea{
    border: 2px solid #8A8AFF;
    border-radius: 5px;
}

.contenedor-inputs input:focus,
.contenedor-inputs textarea:focus{
    border: 3px solid #3D3DFF;
    outline: none;
}

.contenedor-flex-2{
    width: 100%;
    max-width: 50%;

    margin: auto;

}

.recaptcha{
    margin: 35px 0;
}

.item-recaptcha{
    width: 100px !important;
}

.contenedor-boton{

    display: flex;
    justify-content: end;

}

.contenedor-boton button{

    background-color: #8A8AFF;
    color: #000066;

    padding: 10px 15px;

    box-shadow: 0 8px 16px rgba(0, 0, 111, 0.2);

    border: none;
    border-radius: 5px;

    transition: transform 0.3s;

}

.contenedor-boton button:hover{
    background-color: #3D3DFF;
    color: #fff;
    box-shadow: none;
    transform: translateY(-10px);
}

.error-message{
    color: #FF0000;

}

.modal{
    background-color: rgba(0, 0, 0, 0.5);
    position: fixed;
}


@media (max-width: 1024px){
    .contenedor-flex-1{
        flex-direction: column;

        max-width: 80%;
    
        margin: 25px auto;
    
        gap: 0px;
    
    }

    .contenedor-flex-2{
        max-width: 80%;
    
        margin: auto;
    
    }

}