body {
  margin: 0;
  font-family: 'rem';

  font-size: 16px;

  color: #000066;
}


.contenedor-titulo-nav{
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;

  width: 100%;
  margin: auto;

  gap: 10px;
}

.titulo-img-nav{
  width: 100%;
  max-width: 15%;

  height: auto;
}

.titulo-nav{
  font-family: 'rem';

  letter-spacing: -2px;
  font-weight: 800;
  font-size: 2.5rem;

  color: #3D3DFF;
  margin: 0;
}