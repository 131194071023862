.contenedor{
    width: 100%;
    max-width: 80%;

    margin: 100px auto;

    font-family: 'rem';
    font-size: 16px;
}

.titulo{
    font-weight: bold;
}

.contenedor-documentos-cat{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.contenedor-filtro-categoria{
    display: flex;
    flex-wrap: wrap;

    width: 100%;
    max-width: 44%;

    gap: 20px;

    margin: 100px auto;

}

.category-button,
.active {
    display: flex;
    height: auto;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    flex-basis: 6.25rem;

    color: #828282;
    
    background: none;
    border: none;
    display: inline-block;
    padding-bottom: 2px;
    background-image: linear-gradient(#000066 0 0);
    background-position: 0 100%; /* OR bottom left */
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /* change after the size immediately */
}

.category-button:hover,
.link-item-post:hover {
  background-position: 0 100%; /* OR bottom left */
  background-size: 100% 2px;

  
  color: #000066;
}

.active {
  background-position: 0 100%; /* OR bottom left */
  background-size: 100% 2px;
  color: #000066;
}

.contenedor-post {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas con fracción de espacio */
    grid-auto-rows: 1fr; /* Todas las filas tendrán la misma altura */
    gap: 50px;
    
    margin: 50px 0;
}
  
.item-post {
    box-shadow: 0 4px 20px rgba(0, 0, 111, 0.2);
    border-radius: 15px;

    padding: 30px 40px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;

}

.titulo-post{
    font-size: 20px;

    color: #000066;
}

.extracto-post{
    color: #828282;
    font-size: 0.9rem;
    font-weight: 300;

    margin: 0;
}

.link-item-post{
    font-size: 16px;

    text-decoration: none;
    color: #828282;

    display: inline-block;
    padding-bottom: 2px;
    background-image: linear-gradient(#000066 0 0);
    background-position: 0 100%; /* OR bottom left */
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /* change after the size immediately */
}

.icono-link-post{
    font-size: 17px;

    vertical-align: middle;

    padding-left: 5px;
}

@media (max-width: 768px){
    .contenedor-post {
        grid-template-columns: repeat(1, 1fr); /* Dos columnas con fracción de espacio */
        grid-auto-rows: 1fr; /* Todas las filas tendrán la misma altura */
    }
}