.contenedor-historia{
    width: 100%;
    max-width: 80%;

    margin: auto;

    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;
}

.contenedor-titulo-historia{
    margin-top: 100px;
    margin-bottom: 25px;
}

.contenedor-texto-historia{
    text-align: center;
}

.contenedor-contexto{
    width: 100%;
    max-width: 80%;

    margin: 50px auto;

    display: flex;
    flex-direction: row;

    justify-content: center;
}

.img-contexto{
    width: 100%;
    max-width: 50%;
    margin: 25px;
}

.contexto{
    width: 100%;
    max-width: 50%;
    margin: 25px;
}

@media (max-width: 870px){
    .contenedor-contexto{
        flex-direction: column-reverse;
        align-items: center;
    }

    .img-contexto,
    .contexto{
        max-width: 100%;
    }
}