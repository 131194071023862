.contenedor-beneficios-page{
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 80%;

    margin: 100px auto;

}

.titulo-beneficio{
    font-weight: bold;
}

.contenedor-items-beneficios {  
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Dos columnas con fracción de espacio */
    grid-auto-rows: 1fr; /* Todas las filas tendrán la misma altura */
    gap: 50px;

    margin: 50px 0;
}

.item-beneficio-page{
    box-shadow: 0 4px 20px rgba(0, 0, 111, 0.2);
    border-radius: 15px;

    padding: 30px 40px;

    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
}

.titulo-beneficio-item{
    font-size: 1.5rem;
}

.extracto-beneficio-item{
    font-family: 'rem';
    font-size: 16px;
    font-weight: 300;
    color: #828282;
}

.link-item-beneficio{
    font-size: 16px;

    text-decoration: none;
    color: #828282;

    display: inline-block;
    padding-bottom: 2px;
    background-image: linear-gradient(#000066 0 0);
    background-position: 0 100%; /* OR bottom left */
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition:
        background-size 0.3s,
        background-position 0s 0.3s; /* change after the size immediately */
}

.link-item-beneficio:hover{
  background-position: 0 100%; /* OR bottom left */
  background-size: 100% 2px;

  
  color: #000066;
}

@media (max-width: 768px){
    .contenedor-items-beneficios {
        grid-template-columns: repeat(1, 1fr); /* Dos columnas con fracción de espacio */
        grid-auto-rows: 1fr; /* Todas las filas tendrán la misma altura */
    }
}