.contenedor-directorio{
    width: 100%;

    display: flex;
    flex-direction: column;

    margin: 100px auto;

    justify-content: center;
    align-items: center;
}
.contenedor-titulo-directorio{
    width: 100%;

    text-align: center;
    margin-bottom: 50px;
}

.contenedor-item-directorio {
    display: grid;
    width: 100%;
    max-width: 80%;
    justify-content: center;
    align-items: center;
    gap: 4rem; /* Espacio entre los elementos */
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr)); /* Ajusta 150px al tamaño deseado */
}

.item-directorio {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 111, 0.2);
    padding: 1rem; /* Espacio interno de los elementos */
    border-radius: 25px;
}

.item-directorio img{
    border-radius: 25px;
    margin-bottom: 2rem;
}

.nombre-dir{
    font-size: 25px;
    margin: 0;
    color: #0000BD;
}

.cargo-dir{
    font-size: 20px;
}

.contacto-dir{
    font-size: 16px;
    margin: 5px;
}

